<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" page_title="article" />

    <v-card class="mx-3 my-5 rounded-lg">
      <template>
        <v-container fluid>
          <v-row class="d-flex">
            <v-col cols="12" lg="5" md="6" sm="12" class="pa-5">
              <v-img
                alt="OrgImg"
                :src="items.picturebackground"
                class="orgImg"
              ></v-img>
            </v-col>
            <v-col cols="12" lg="6" md="6" class="pt-5">
              <v-card-title class="Casetext">{{
                items.contentName
              }}</v-card-title>

              <v-card-subtitle
                style="font-size: 18px; opacity: 0.8; line-height: 25px"
                class="mt-3"
              >
                <div>
                  <p class="breaktext">{{ items.wordContentText }}</p>
                </div>
              </v-card-subtitle>

              <v-row no-gutters class="mt-10" cols="6" v-if="hasLink">                
                <v-col class="pl-4 d-flex">
                  <p
                    class="mb-0 mr-12"
                    style="font-weight: bold; font-size: 20px"
                  >
                    {{ $t("link") }}
                  </p>
                  <p
                    @click="clickLink(items.link)"
                    style="
                      font-size: 14px;
                      opacity: 0.8;
                      margin-top: 25px;
                      cursor: pointer;
                    "
                    class="mb-0 mt-1 textlink text-decoration-underline"
                  >
                    {{ cutText(items.link) }}
                  </p></v-col
                >
              </v-row>
              <v-card-actions class="d-flex pt-6" style="justify-content: center !important;">
                  <v-btn
                    v-if="items.pdfUrl != null && items.pdfUrl != ''"
                    depressed
                    color="#A6CC39"
                    @click="
                      clickLink(items.pdfUrl) && items.pdfUrl.trim().length > 0
                    "
                    class="login-phone text-capitalize"
                    download
                    >{{ $t("DownloadPDF") }}</v-btn
                  >
              </v-card-actions>
            </v-col>
            <v-col>
              <v-icon
                :color="Addfav == true ? '#FF6060' : ''"
                @click="toggleCheck"
                class="heart-icon"
                >{{ heartIcon }}</v-icon
              >
            </v-col>
          </v-row>
        </v-container>
      </template>
      <div class="d-flex" style="justify-content: center">
        <div style="height: 1px; width: 95%; background-color: #00000027"></div>
      </div>
      <template>
        <div class="scrollbar-container mt-7">
          <div class="video-scrollbar">
            <v-row no-gutters>
              <v-col
                cols="12"
                class="pl-3"
                style="
                  display: flex;
                  justify-content: center;
                  vertical-align: middle;
                "
              >
                <v-avatar size="45" class="marginRight-6">
                  <v-img
                    v-if="profileImg === null"
                    src="@/assets/defaultprofile.png"
                  ></v-img>
                  <v-img v-else :src="profileImg"></v-img>
                </v-avatar>
                <v-text-field
                  v-model="new_comment"
                  :label="$t('writeacomment')"
                  outlined
                  @input="checkCommentLength(new_comment, 1)"
                  dense
                  class="video-text-field mt-1 comment-textbox"
                  hide-details="auto"
                  maxlength="100"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                class="py-3"
                style="display: flex; justify-content: end"
              >
                <v-btn
                  depressed
                  text
                  outlined
                  width="80"
                  height="35"
                  class="text-capitalize ml-5"
                  style="border-radius: 8px; margin-left: 5px"
                  @click="(new_comment = ''), (mainComNull = true)"
                >
                  {{ $t("cancel") }}
                </v-btn>
                <v-btn
                  :disabled="mainComNull"
                  width="80"
                  height="35"
                  class="text-capitalize btn_hover_effect ml-5"
                  style="color: #ffff; border-radius: 8px"
                  color="#A6CC39"
                  @click="AddNewComment()"
                >
                  {{ $t("send") }}
                </v-btn>
              </v-col>
            </v-row>
            <div class="comment-paddingLeft4">
              <p class="mb-0" style="font-weight: bold; color: black">
                {{ commentItems.length != 0 ? commentItems.length : "0" }}
                {{ $t("comments") }}
              </p>
            </div>
            <div
              class="comment-paddingLeft4 pt-3"
              v-for="(comment, index) in commentItems"
              :key="index"
            >
              <v-row no-gutters>
                <!-- outer comment part -->
                <v-col cols="11" md="11" style="display: flex">
                  <v-row no-gutters>
                    <v-col cols="12" style="display: flex; flex-direction: row">
                      <v-avatar
                        justify="center"
                        align="center"
                        size="35"
                        class="marginRight-6"
                      >
                        <v-img
                          v-if="comment.profileImage"
                          :src="comment.profileImage"
                        ></v-img>
                        <v-img v-else src="@/assets/defaultprofile.png"></v-img>
                      </v-avatar>
                      <!-- show comment/show edit text-box -->
                      <div class="d-flex flex-column" style="width: 100%">
                        <div v-if="!comment.editCommentFlag">
                          <p class="mb-1">
                            {{ comment.fullname }}
                            {{ comment.commentedTime }}
                            <br />
                            {{ comment.commentMessage }}
                          </p>
                        </div>
                        <!-- when edit comment show this -->
                        <div v-else>
                          <v-row no-gutters>
                            <v-col cols="12" class="d-flex">
                              <v-text-field
                                :placeholder="$t('writeacomment')"
                                v-model="comment.tempComment"
                                @input="
                                  checkCommentLength(comment.tempComment, 2)
                                "
                                hide-details="auto"
                                maxlength="100"
                                class="comment-textbox"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row class="mt-0">
                            <v-col
                              style="
                                display: flex;
                                justify-content: end;
                                align-items: center;
                              "
                            >
                              <v-btn
                                depressed
                                text
                                outlined
                                width="80"
                                height="35"
                                class="text-capitalize"
                                style="border-radius: 8px"
                                @click="cancelComment(comment)"
                              >
                                {{ $t("cancel") }}
                              </v-btn>
                              <v-btn
                                :disabled="commentNull"
                                width="100"
                                height="35"
                                class="text-capitalize btn_hover_effect ml-5"
                                style="
                                  color: #ffff;
                                  border-radius: 8px;
                                  padding: 0.3rem 0px 0.3rem 0px;
                                "
                                color="#A6CC39"
                                @click="UpdateComment(comment)"
                              >
                                {{ $t("update") }}
                              </v-btn>
                            </v-col>
                          </v-row>
                        </div>
                        <!-- reply btn under each comment -->
                        <div class="pt-1">
                          <v-btn
                            style="
                              font-size: 14px;
                              min-width: 54px;
                              min-height: 22px;
                              height: auto !important;
                              justify-content: center;
                              background-color: #f0f0f0;
                              opacity: 0.42;
                              color: #424242;
                              opacity: 1;
                              border-radius: 4px;
                              padding: 0;
                            "
                            text
                            class="text-capitalize"
                            @click="ClickCommentReply(comment)"
                          >
                            {{ $t("reply") }}
                          </v-btn>
                        </div>
                        <!-- show replies count text under reply btn -->
                        <div
                          v-if="comment.replyCommentLists.length != 0"
                          @click="showReplylist(comment)"
                        >
                          <p
                            style="
                              color: blue;
                              cursor: pointer;
                              font-size: 14px;
                              text-transform: lowercase;
                            "
                            class="mb-1"
                          >
                            <v-icon style="color: blue" v-if="comment.replyFlag"
                              >mdi-chevron-down</v-icon
                            >
                            <v-icon v-else style="color: blue"
                              >mdi-chevron-up</v-icon
                            >{{ comment.replyCommentLists.length }}
                            {{
                              comment.replyCommentLists.length > 1
                                ? $t("replies")
                                : $t("reply")
                            }}
                          </p>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- dot actions for editing comments -->
                <v-col
                  v-if="
                    comment.memberID == loginmemberID ||
                    loginmemberID == memberID
                  "
                  cols="1"
                  md="1"
                  class="px-0"
                  style="display: flex; justify-content: end"
                >
                  <template>
                    <div class="d-flex" style="margin-top: -5px">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            v-bind="attrs"
                            v-on="on"
                            style="min-width: 32px !important;padding: 0px !important;"
                          >
                            <v-icon style="color: #424242"
                              >mdi-dots-vertical</v-icon
                            >
                          </v-btn>
                        </template>

                        <v-list class="d-flex flex-column align-start">
                          <div>
                            <v-list-item
                              class="list-item-padding"
                              v-show="loginmemberID == comment.memberID"
                            >
                              <v-btn
                                color="#424242"
                                style="width: 100px; justify-content: left"
                                text
                                class="text-capitalize"
                                @click="editComment(comment)"
                              >
                                <v-icon left>mdi-square-edit-outline</v-icon>
                                {{ $t("edit") }}
                              </v-btn>
                            </v-list-item>
                            <v-list-item class="list-item-padding">
                              <v-btn
                                color="#FF6060"
                                style="width: 100px; justify-content: left"
                                text
                                class="text-capitalize"
                                @click="prepareDeleteComment(comment)"
                              >
                                <v-icon left>mdi-delete-outline</v-icon>
                                {{ $t("delete") }}
                              </v-btn>
                            </v-list-item>
                          </div>
                        </v-list>
                      </v-menu>
                    </div>
                  </template>
                </v-col>
              </v-row>
              <!-- reply text box to outer comment -->
              <v-row no-gutters v-if="comment.showOuterCommentBox" class="mt-3">
                <v-col cols="11" class="reply-textbox-paddingLeft d-flex flex-column">
                  <div class="d-flex" style="width: 100%">
                    <v-avatar
                      class="marginRight-6"
                      justify="center"
                      align="center"
                      size="40"
                    >
                      <v-img
                        v-if="profileImg === null"
                        src="@/assets/defaultprofile.png"
                      ></v-img>
                      <v-img v-else :src="profileImg"></v-img>
                    </v-avatar>
                    <div class="d-flex" style="width: 100%">
                      <v-text-field
                        :placeholder="$t('writeareply')"
                        v-model="comment.tempReplyMsg"
                        @input="checkCommentLength(comment.tempReplyMsg, 3)"
                        hide-details="auto"
                        maxlength="100"
                        class="video-text-field comment-textbox"
                        dense
                      ></v-text-field>
                    </div>
                  </div>
                  <div class="mt-3 d-flex justify-end" style="width: 100%">
                    <v-btn
                      depressed
                      text
                      outlined
                      width="80"
                      height="35"
                      class="text-capitalize ml-5"
                      style="border-radius: 8px; margin-left: 5px"
                      @click="
                        (comment.tempReplyMsg = ''),
                          (mainComNull = true),
                          (comment.showOuterCommentBox = false)
                      "
                    >
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      :disabled="replyCommentNull"
                      class="text-capitalize btn_hover_effect ml-5"
                      style="
                        color: #ffff;
                        border-radius: 8px;
                        font-size: 14px;
                        min-width: 80px !important;
                        min-height: 35px !important;
                        width: auto !important;
                        padding: 0;
                      "
                      color="#A6CC39"
                      @click="AddReplyToComment(comment, null)"
                    >
                      {{ $t("reply") }}
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <!-- replies of each related comment -->
              <v-row
                class="mt-1 comment-paddingLeft4"
                v-for="(repcomment, repIndex) in comment.replyCommentLists"
                :key="repIndex"
                v-show="comment.replyFlag"
              >
                <v-col cols="11" md="11" class="reply-textbox-paddingLeft d-flex flex-column pr-0">
                  <div class="d-flex" style="width: 100%">
                    <v-avatar
                      class="marginRight-6"
                      justify="center"
                      align="center"
                      size="35"
                    >
                      <v-img
                        v-if="repcomment.profileImage"
                        :src="repcomment.profileImage"
                      ></v-img>
                      <v-img v-else src="@/assets/defaultprofile.png"></v-img>
                    </v-avatar>
                    <div class="d-flex flex-column" style="width: 100%">
                      <p class="mb-0">
                        {{ repcomment.fullname }}
                        {{ repcomment.commentedTime }}
                      </p>
                      <div
                        v-if="!repcomment.showInside_ReplyBox"
                        style="display: flex; align-items: center"
                      >
                        <p
                          style="color: #34b4eb; margin-right: 10px"
                          class="mb-0"
                        >
                          {{ repcomment.replyFullname }}
                        </p>
                      </div>
                      <div v-if="!repcomment.showEditReplyBox" class="mb-2">
                        {{ repcomment.replyMessage }}
                      </div>
                      <div v-else>
                        <div>
                          <v-text-field
                            :placeholder="$t('writeareply')"
                            v-model="repcomment.tempEditReplyText"
                            @input="
                              checkCommentLength(
                                repcomment.tempEditReplyText,
                                4
                              )
                            "
                            hide-details="auto"
                            maxlength="100"
                            class="video-text-field comment-textbox"
                            dense
                          ></v-text-field>
                        </div>
                        <div
                          class="mt-3 d-flex justify-end"
                          style="width: 100%"
                        >
                          <v-btn
                            depressed
                            text
                            outlined
                            width="80"
                            height="35"
                            class="text-capitalize"
                            style="border-radius: 8px"
                            @click="
                              () => {
                                repcomment.showEditReplyBox = false;
                                repcomment.tempEditReplyText = '';
                              }
                            "
                          >
                            {{ $t("cancel") }}
                          </v-btn>
                          <v-btn
                            :disabled="editReplyNull"
                            width="100"
                            height="35"
                            class="text-capitalize btn_hover_effect ml-5"
                            style="
                              color: #ffff;
                              border-radius: 8px;
                              padding: 0.3rem 0px 0.3rem 0px;
                            "
                            color="#A6CC39"
                            @click="UpdateReplyText(repcomment, comment)"
                          >
                            {{ $t("update") }}
                          </v-btn>
                        </div>
                      </div>
                      <div>
                        <v-btn
                          style="
                            font-size: 14px;
                            min-width: 54px;
                            min-height: 22px;
                            height: auto !important;
                            justify-content: center;
                            background-color: #f0f0f0;
                            opacity: 0.42;
                            color: #424242;
                            opacity: 1;
                            border-radius: 4px;
                            padding: 0;
                          "
                          text
                          class="text-capitalize"
                          v-if="!repcomment.showInside_ReplyBox"
                          @click="replyToInnerComment(repcomment)"
                        >
                          {{ $t("reply") }}
                        </v-btn>
                      </div>
                    </div>
                  </div>
                </v-col>
                <!-- dot action btns of inner reply comments -->
                <v-col
                  cols="1"
                  md="1"
                  class="px-0"
                  style="display: flex; justify-content: end"
                  v-if="
                    repcomment.memberID == loginmemberID ||
                    loginmemberID == memberID
                  "
                >
                  <template>
                    <div class="d-flex" style="margin-top: -5px">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            text
                            v-bind="attrs"
                            v-on="on"
                            class="mr-0"
                            style="min-width: 32px !important"
                          >
                            <v-icon style="color: #424242"
                              >mdi-dots-vertical</v-icon
                            >
                          </v-btn>
                        </template>
                        <v-list class="d-flex flex-column align-start">
                          <div>
                            <v-list-item
                              class="list-item-padding"
                              v-show="loginmemberID == repcomment.memberID"
                            >
                              <v-btn
                                color="#424242"
                                style="
                                  font-size: 16px;
                                  width: 100px;
                                  justify-content: left;
                                "
                                text
                                class="text-capitalize"
                                @click="editCommentReply(repcomment, comment)"
                              >
                                <v-icon left>mdi-square-edit-outline</v-icon>
                                {{ $t("edit") }}
                              </v-btn>
                            </v-list-item>
                            <v-list-item class="list-item-padding">
                              <v-btn
                                color="#FF6060"
                                style="width: 100px; justify-content: left"
                                text
                                class="text-capitalize"
                                @click="prepareDeleteReply(repcomment)"
                              >
                                <v-icon left>mdi-delete-outline</v-icon>
                                {{ $t("delete") }}
                              </v-btn>
                            </v-list-item>
                          </div>
                        </v-list>
                      </v-menu>
                    </div>
                  </template>
                </v-col>
                <!-- inner reply box -->
                <v-col
                  cols="12"
                  md="12"
                  class="reply-textbox-paddingLeft"
                  v-if="repcomment.showInside_ReplyBox"
                  style="display: flex; justify-content: center"
                >
                  <v-row no-gutters>
                    <v-col cols="11" class="reply-textbox-paddingLeft d-flex flex-column">
                      <div class="d-flex" style="width: 100%">
                        <v-avatar
                          class="marginRight-6"
                          justify="center"
                          align="center"
                          size="40"
                        >
                          <v-img
                            v-if="profileImg === null"
                            src="@/assets/defaultprofile.png"
                          ></v-img>
                          <v-img v-else :src="profileImg"></v-img>
                        </v-avatar>
                        <div class="d-flex" style="width: 100%">
                          <v-text-field
                            :placeholder="$t('writeareply')"
                            v-model="repcomment.tempCommentReplyMsg"
                            @input="
                              checkCommentLength(
                                repcomment.tempCommentReplyMsg,
                                5
                              )
                            "
                            hide-details="auto"
                            maxlength="100"
                            class="video-text-field comment-textbox"
                            dense
                          ></v-text-field>
                        </div>
                      </div>
                      <div class="mt-3 d-flex justify-end" style="width: 100%">
                        <v-btn
                          depressed
                          text
                          outlined
                          width="80"
                          height="35"
                          class="text-capitalize ml-5"
                          style="border-radius: 8px; margin-left: 5px"
                          @click="closeReplyMessage(repcomment)"
                        >
                          {{ $t("cancel") }}
                        </v-btn>
                        <v-btn
                          :disabled="replyInnerComNull"
                          class="text-capitalize btn_hover_effect ml-5"
                          style="
                            color: #ffff;
                            border-radius: 8px;
                            font-size: 14px;
                            min-width: 80px !important;
                            min-height: 35px !important;
                            width: auto !important;
                            padding: 0;
                          "
                          color="#A6CC39"
                          @click="AddReplyToComment(comment, repcomment)"
                        >
                          {{ $t("reply") }}
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </template>
    </v-card>
    <v-dialog
      v-model="delete_dialog"
      max-width="470px"
      max-height="87px"
      style="border-radius: 15px !important"
    >
      <v-card style="border-radius: 15px !important">
        <v-spacer></v-spacer>
        <v-card-title class="flex justify-center items-center mb-4">
          <p class="mb-0" style="font-size: 20px; font-weight: bold">
            {{$t("deletecomment")}}
          </p>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="closemessageDialog()"
            color="#424242"
            style="
              border-radius: 8px !important;
              border: 1px solid rgba(66, 66, 66, 0.05);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="text-left">
          <p
            v-if="deleteType === 'comment'"
            style="font-size: 20px; color: #363636; opacity: 1"
            class="mb-1"
          >
            {{$t("doyouwanttodeletecomment")}}
          </p>
          <p
            v-else
            style="font-size: 20px; color: #363636; opacity: 1"
            class="mb-1"
          >
            {{$t("doyouwanttodeletereplycomment")}}
          </p>
        </v-card-text>
        <v-divider class="mb-2" style="opacity: 0.5"></v-divider>
        <v-card-actions class="justify-end" style="padding-bottom: 15px">
          <v-btn
            width="70"
            height="39"
            class="text-capitalize ml-5"
            style="border-radius: 8px; font-size: 14px"
            color="#F0F0F0 "
            @click="closemessageDialog()"
            >{{$t("No")}}</v-btn
          >
          <v-btn
            width="76"
            height="39"
            class="text-capitalize btn_hover_effect ml-5"
            style="
              color: #ffff;
              border: 1px solid #a6cc39;
              border-radius: 8px;
              font-size: 14px;
            "
            color="#A6CC39"
            @click="
              deleteType === 'comment'
                ? DeleteComment(deleteComTemp.commentID)
                : DeleteReply(deleteReplyTemp.replyID)
            "
            >{{$t("Yes")}}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      //...comment related......
      memberID: "",
      deleteReplyTemp: null,
      deleteComTemp: null,
      deleteType: null,
      delete_dialog: false,
      loginmemberID: localStorage.getItem("memberID"),
      new_comment: "",
      commentItems: [],
      commentNull: true,
      replyCommentNull: true,
      mainComNull: true,
      editReplyNull: true,
      replyInnerComNull: true,
      profileImg: localStorage.getItem("profileimage"),
      //......................
      hasLink: false,
      items: [],
      Addfav: false,
      breadcrumbs: [
        {
          text: "library",
          href: "/library/",
        },
        {
          text: "article",
          href: "/library/",
        },
      ],
      maxlength: 20,
    };
  },
  computed: {
    heartIcon() {
      return this.Addfav == true ? "mdi-heart" : "mdi-heart-outline";
    },
  },
  watch: {
    "items.link"(newValue) {
      this.hasLink = newValue != null && newValue.trim().length > 0;
    },
  },
  mounted() {
    this.getFavourite();
    this.getNotificationCount();
    this.GetCommentsForArticle();
    this.GetArticleByID();
    if (this.$store.state.library.notificationTransactionIDForArticle != 0) {
      this.Readnoti(
        this.$store.state.library.notificationTransactionIDForArticle,
        1
      );
    }
    this.UpdateCompleteArticle();
  },
  methods: {
    //.......comment related..............................
    async DeleteReply(ID) {
      let self = this;
      await axios
        .post(`${self.web_url}Comment/DeleteReplyComment?replyID=` + ID)
        .then(function (res) {
          if (res.data.status == 0) {
            self.delete_dialog = false;
            self.GetCommentsForArticle();
          }
        })
        .catch(function (err) {
          alert(err);
        });
    },
    async DeleteComment(ID) {
      let self = this;
      await axios
        .post(`${self.web_url}Comment/DeleteComment?CommentID=` + ID)
        .then(function (res) {
          if (res.data.data && res.data.data.length > 0) {
            self.delete_dialog = false;
            self.deleteComTemp = null;
            self.GetCommentsForArticle();
          }
        })
        .catch(function (err) {
          alert(err);
        });
    },
    async UpdateReplyText(reply, comment) {
      let self = this;
      self.editReplyNull = true;
      const request = {
        replyID: reply.replyID,
        replyMessage: reply.tempEditReplyText,
      };
      axios
        .post(`${self.web_url}Comment/UpdateReplyComment`, request)

        .then(function (result) {
          if (result.data.status == 0) {
            comment.replyFlag = false;
            self.GetCommentsForArticle();
          }
          self.commentNull = false;
        });
    },
    AddReplyToComment(item, reply) {
      let self = this;
      self.replyCommentNull = true;
      self.replyInnerComNull = true;
      let tempReplyID = reply?.replyID || null;
       if(reply != null && reply.memberID == self.loginmemberID){
        tempReplyID = null;
      }
      let tempReply_msg = reply?.tempCommentReplyMsg || "";
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        commentID: item.commentID,
        replyMessage: reply != null ? tempReply_msg : item.tempReplyMsg,
        replyID: tempReplyID,
      };
      axios
        .post(`${self.web_url}Comment/AddReplyComment`, request)

        .then(function (result) {
          if (result.data.status == 0) {
            self.GetCommentsForArticle();
          }
          self.commentNull = false;
        });
    },
    async UpdateComment(update_value) {
      let self = this;
      self.commentNull = true;
      const request = {
        commentID: update_value.commentID,
        commentMessage: update_value.tempComment,
      };
      axios
        .post(`${self.web_url}Comment/UpdateComment`, request)

        .then(function (result) {
          if (result.data.status == 0) {
            self.GetCommentsForArticle();
          }
        });
    },
    AddNewComment() {
      let self = this;
      self.mainComNull = true;
      const request = {
        companyID: localStorage.getItem("companyID"),
        userID: parseInt(localStorage.getItem("UserID")),
        videoID: parseInt(self.$route.query.article_id), // article id
        updateBy: null,
        createdBy: localStorage.getItem("memberID"),
        commentMessage: self.new_comment,
        Page: 3, //for sound page comment
      };
      console.log("add com ", request);
      axios
        .post(`${self.web_url}Comment/AddComment`, request)

        .then(function (result) {
          if (result.data.status == 0) {
            self.GetCommentsForArticle();
          }
        });
      self.new_comment = "";
    },
    prepareDeleteReply(repcomment) {
      let self = this;
      self.deleteType = "reply";
      self.delete_dialog = true;
      self.deleteReplyTemp = repcomment;
    },
    closemessageDialog() {
      this.delete_dialog = false;
    },
    async prepareDeleteComment(comment) {
      let self = this;
      self.delete_dialog = true;
      self.deleteComTemp = comment;
      self.deleteType = "comment";
    },
    closeReplyMessage(item) {
      item.showInside_ReplyBox = false;
      item.tempCommentReplyMsg = "";
      this.commentNull = false;
    },
    replyToInnerComment(repcomment) {
      let self = this;
      self.commentItems.forEach((element) => {
        element.replyCommentLists.forEach((item) => {
          (item.showInside_ReplyBox = false),
            (item.showEditReplyBox = false),
            (item.tempEditReplyText = "");
        });
      });
      repcomment.showInside_ReplyBox = true; //item.showOuterCommentBox = true
      self.replyInnerComNull = true;
      //self.showUpdateBtnFlag = false;
    },
    editCommentReply(repcomment, comment) {
      let self = this;

      self.commentItems.forEach((element) => {
        element.replyCommentLists.forEach((item) => {
          (item.showInside_ReplyBox = false),
            (item.showEditReplyBox = false),
            (item.tempEditReplyText = "");
        });
      });

      self.commentItems[comment.no - 1].replyFlag = true;
      self.commentItems[comment.no - 1].replyCommentLists[
        repcomment.no - 1
      ].showEditReplyBox = true;
      self.commentItems[comment.no - 1].replyCommentLists[
        repcomment.no - 1
      ].tempEditReplyText = repcomment.replyMessage;
      self.editReplyNull = repcomment.replyMessage.length === 0;
    },
    cancelComment(com) {
      //let self = this;
      com.editCommentFlag = false;
      //self.showUpdateBtnFlag = false;
      //self.commentNull = false;
    },
    ClickCommentReply(comment) {
      let self = this;
      self.commentItems.forEach((element) => {
        element.editCommentFlag = false;
        element.replyFlag = false;
        element.showOuterCommentBox = false;
        element.tempReplyMsg = "";
        element.replyCommentLists.forEach((item) => {
          (item.showInside_ReplyBox = false),
            (item.showEditReplyBox = false),
            (item.tempEditReplyText = ""),
            (item.tempCommentReplyMsg = "");
        });
      });

      self.commentItems[comment.no - 1].showOuterCommentBox = true;
      self.replyCommentNull = true;
    },
    editComment(com) {
      let self = this;
      self.commentItems.forEach((element) => {
        element.editCommentFlag = false;
        element.replyFlag = false;
        element.showOuterCommentBox = false;
        element.replyCommentLists.forEach((item) => {
          (item.showInside_ReplyBox = false),
            (item.showEditReplyBox = false),
            (item.tempEditReplyText = ""),
            (item.tempCommentReplyMsg = "");
        });
      });

      self.commentItems[com.no - 1].editCommentFlag = true;
      self.commentItems[com.no - 1].tempComment = com.commentMessage;
      self.commentNull = com.commentMessage.length === 0;
      com.editCommentFlag = true;
    },
    showReplylist(comment) {
      comment.replyFlag = !comment.replyFlag;
    },
    checkCommentLength(v, type) {
      if (v.length > 99) {
        alert("Message should be no more than 100 characters.");
      }
      switch (type) {
        case 1:
          this.mainComNull = v.length === 0;
          break;
        case 2:
          this.commentNull = v.length === 0;
          break;
        case 3:
          this.replyCommentNull = v.length === 0;
          break;
        case 4:
          this.editReplyNull = v.length === 0;
          break;
        case 5:
          this.replyInnerComNull = v.length === 0;
          break;
      }
    },
    async GetCommentsForArticle() {
      let self = this;
      self.new_comment = "";
      await this.$axios
        .get(
          `${self.web_url}Comment/GetComments?VideoID=${
            self.$route.query.article_id
          }&Page=${3}`
        )
        .then(function (res) {
          console.log("all comments ", res.data.data);
          if (res.data.data.length > 0) {
            self.commentItems = res.data.data.map((v, i) => ({
              ...v,
              no: i + 1,
              replyFlag: false,
              editCommentFlag: false,
              showOuterCommentBox: false,
              showOuterReplyBox: false,
              tempComment: "",
              tempReplyMsg: "",
              replyCommentLists: v.replyCommentLists.map((j, k) => ({
                ...j,
                no: k + 1,
                tempEditReplyText: "",
                tempCommentReplyMsg: "",
                showEditReplyBox: false,
                showInside_ReplyBox: false,
              })),
            }));
          }
        });
    },
    //............................... end of comment related..........................
    cutText(v) {
      if (v.length <= this.maxlength) {
        return v;
      } else {
        return v.substring(0, this.maxlength) + "...";
      }
    },
    clickLink(link) {
      if (!link.startsWith("http://") && !link.startsWith("https://")) {
        link = "http://" + link;
      }
      window.open(link, "_blank");
    },
    Readnoti(notificationid, pageid) {
      let self = this;
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        notificationID: notificationid,
        notiPage: pageid,
      };
      axios
        .post(`${self.web_url}Notification/AddUserIDinNotiJoin`, request)
        .then(function (result) {
          if (result.data.status == 0) {
            self.getNotificationCount();
          }
        });
    },
    async getNotificationCount() {
      let self = this;
      const request = {
        userId: parseInt(localStorage.getItem("UserID")),
        companyId: localStorage.getItem("companyID"),
      };
      await axios
        .post(
          `${self.web_urlV3}Notification/GetAllNotificationMobileV4`,
          request
        )
        .then(function (result) {
          if (result.data.status == 0) {
            let d1 = result.data.data.filter(
              (x) => x.viewOrNot === false
            ).length;
            let d2 = result.data.data1.filter(
              (x) => x.newNoti === false
            ).length;
            let d3 = result.data.data2.filter(
              (x) => x.newNoti === false
            ).length;
            let d4 = result.data.data4.filter(
              (x) => x.newNoti === false
            ).length;
            let d5 = result.data.data5.filter(
              (x) => x.newNoti === false
            ).length;
            let d6 = result.data.data6.filter(
              (x) => x.newNoti === false
            ).length;
            let d7 = result.data.data7.filter(
              (x) => x.newNoti === false
            ).length;
            let d8 = result.data.data8.filter(
              (x) => x.newNoti === false
            ).length;
            let d9 = result.data.data3.filter(
              (x) => x.newNoti === false
            ).length;
            let notiCount = d1 + d2 + d3 + d4 + d5 + d6 + d7 + d8 + d9;
            self.$store.commit("savenotiCount", notiCount);
          }
        });
    },
    async AddReportActivity(id) {
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        id: id,
        minutes: "1",
        separatePage: 3,
        progressTime: "",
        actualViewTime: "",
      };
      await this.$axios.post(
        `${this.web_url}Reports/AddReportActivity`,
        request
      );
    },
    toggleCheck() {
      this.Addfav = !this.Addfav;
      this.updateFavourite(this.Addfav);
    },
    updateFavourite(isFavourite) {
      let self = this;
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        contentID: parseInt(self.items.id),
        contentCheck: 1,
        favFlag: isFavourite,
      };
      axios
        .post(`${this.web_url}Contents/UpdateFavorite`, request)
        .then(function (res) {
          if (res.data.status == 0) {
            if (isFavourite == true) {
              self.$store.commit(
                "savefavCount",
                self.$store.state.library.favCount + 1
              );
            } else {
              self.$store.commit(
                "savefavCount",
                self.$store.state.library.favCount - 1
              );
            }
          }
        });
    },
    async GetArticleByID() {
      let self = this;
      await axios
        .get(
          `${this.web_url}Contents/GetArticleByID?id=` +
            this.$route.query.article_id +
            `&UserID=` +
            parseInt(localStorage.getItem("UserID"))
        )
        .then(function (res) {
          self.Addfav = res.data.data.favFlag;
          self.items = res.data.data;
          self.memberID = self.items.memberID;
          self.AddReportActivity(res.data.data.id);
        });
    },
    async UpdateCompleteArticle() {
      const request = {
        userID: parseInt(localStorage.getItem("UserID")),
        lessonID: parseInt(this.$route.query.article_id),
      };
      axios.post(`${this.web_url}Contents/UpdateCompleteArticle`, request);
    },
  },
};
</script>

<style scoped>
.breaktext {
  white-space: pre-line;
}
.orgImg {
  width: 600px;
  height: 375px;
  border-radius: 20px !important;
}
.Caseicon {
  left: 20px;
  color: #ff6060 !important;
  font-size: 45px !important;
  top: 18px;
}
.login-phone {
  color: #fff;
  font-size: 18px;
  z-index: 0;
  position: relative;
}
.login-phone:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.login-phone:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.login-phone:hover {
  transition: 0.25s;
}
.login-phone:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}

/* Heart icon */
.heart-icon:hover {
  cursor: pointer;
}
.heart-icon {
  top: 20px;
  font-size: 50px;
  width: 50px;
}
.textlink {
  color: blue !important;
}

.Casetext {
  color: rgb(66, 66, 66);
  font-weight: bold;
  font-size: 23px;
}
</style>
<!-- comment section css -->
<style scoped>
.comment-paddingLeft4 {
  padding-left: 16px !important;
}
.marginRight-6 {
  margin-right: 24px !important;
}
.reply-textbox-paddingLeft {
  padding-left: 60px !important;
}
::v-deep .primary--text {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}
::v-deep .comment-textbox {
  color: #a6cc39 !important;
  caret-color: #a6cc39 !important;
}
.btn_hover_effect,
.reset-btn {
  color: #fff;
  width: 80%;
  font-size: 18px;
  z-index: 0;
  position: relative;
  display: inline-block;
}
.btn_hover_effect:before,
.reset-btn:before {
  background-color: #4fb14e;
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  opacity: 0.5 !important;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.btn_hover_effect:hover:before,
.reset-btn:hover:before {
  background-color: #4fb14e;
  top: 0;
}
.btn_hover_effect:hover,
.reset-btn:hover {
  transition: 0.25s;
}
.btn_hover_effect:after,
.reset-btn:after {
  position: absolute;
  right: 2.34375rem;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(50%);
  -ms-transform: translateY(-50%) translateX(50%);
  transform: translateY(-50%) translateX(50%);
}
::v-deep .v-dialog {
  overflow-x: hidden;
  overflow-y: hidden;
}
.scrollbar-container {
  padding-right: 5px;
  height: auto;
  padding: 0 0 1rem 0;
  /* padding: 0 2.2rem 0 1.5rem; */
}
.video-scrollbar {
  /* padding-bottom: 2.5rem; */
  padding: 0 2.4rem 0 1.7rem;
  height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}
@media screen and (max-width: 768px) {
  .video-text-field {
    margin-left: 1.6rem;
  }
  .scrollbar-container {
    padding-right: 5px;
    height: auto;
    padding-bottom: 1rem;
    padding-left: 0px;
  }
  .video-scrollbar {
    padding-bottom: 2rem;
    height: 30vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
  }
}
@media only screen and (max-width: 600px){
  .marginRight-6 {
    margin-right: 5px !important;
  }
  .reply-textbox-paddingLeft {
    padding-left: 0px !important;
  }
  .comment-paddingLeft4 {
    padding-left: 10px !important;
  }
  .video-scrollbar {
    padding: 0;
    height: 25vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: thin;
  }
}
</style>
